<template>
    <div id="dc-tickets">
        <div id="dc-header">
            <h3>Tickets</h3>
            <b-button class="dc-button-primary" @click="onClickGoToNewTicket">Add new</b-button>
            <b-button class="dc-button-primary" @click="onClickCreateInvoice" :disabled="invoiceTicketsList.length === 0">Create Invoice</b-button>

            <div id="dc-reload" @click="getTickets()">
                <b-icon icon="arrow-clockwise" style="width: 40px; height: 40px;"></b-icon>
            </div>
        </div>

        <!-- Dropdown -->
        <div id="dc-dropdown-customer-container">
            <!-- <CustomerDropdown @select-customer="onSelectCustomer" /> -->
            <!-- <b-button class="dc-button-primary" @click="onClickClearDropdown">Clear</b-button> -->
        </div>

        <hr />

        <!-- Loading Spinner -->
        <div v-if="ticketsAll.length == 0" class="dc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>

        <!-- Tabs -->
        <b-tabs card v-else>
            <b-tab title="On-Hold" active>
                <!-- On-Hold -->
                <v-client-table :data="ticketsOnHold" :columns="columns" :options="options">
                    <div class="dc-actions-container" slot="actions" slot-scope="{ row }">
                        <b-icon-pencil-square class="dc-icon-edit" @click="onClickEditTicket(row.id)"></b-icon-pencil-square>
                        <b-icon-trash class="dc-icon-delete" @click="onClickDeleteTicket(row.id)"></b-icon-trash>
                        <b-form-checkbox size="lg" v-show="!row.quickbooksInvoice" @change="onChangeCheckbox($event, row)"></b-form-checkbox>
                    </div>
                </v-client-table>
            </b-tab>
            <b-tab title="Completed">
                <!-- Completed -->
                <v-client-table :data="ticketsCompleted" :columns="columns" :options="options">
                    <div class="dc-actions-container" slot="actions" slot-scope="{ row }">
                        <b-icon-pencil-square class="dc-icon-edit" @click="onClickEditTicket(row.id)"></b-icon-pencil-square>
                        <b-icon-trash class="dc-icon-delete" @click="onClickDeleteTicket(row.id)"></b-icon-trash>
                        <b-form-checkbox size="lg" v-show="!row.quickbooksInvoice" @change="onChangeCheckbox($event, row)"></b-form-checkbox>
                    </div>
                </v-client-table>
            </b-tab>
            <b-tab title="All">
                <!-- All -->
                <v-client-table :data="ticketsAll" :columns="columns" :options="options">
                    <div class="dc-actions-container" slot="actions" slot-scope="{ row }">
                        <b-icon-pencil-square class="dc-icon-edit" @click="onClickEditTicket(row.id)"></b-icon-pencil-square>
                        <b-icon-trash class="dc-icon-delete" @click="onClickDeleteTicket(row.id)"></b-icon-trash>
                        <b-form-checkbox size="lg" v-show="!row.quickbooksInvoice" @change="onChangeCheckbox($event, row)"></b-form-checkbox>
                    </div>
                </v-client-table>
            </b-tab>
        </b-tabs>

        <!-- Create Invoice -->
        <b-modal id="dc-modal-create-invoice" title="Create Invoice" centered no-close-on-backdrop>
            <p class="my-4">{{ newInvoice.message }}</p>
        </b-modal>

        <!-- Modal - Delete Ticket -->
        <b-modal id="dc-modal-delete-ticket" ref="dc-modal-delete-ticket" size="md" title="Delete Ticket" centered hide-footer>
            <div style="display: flex; align-items: center; justify-content: center">
                <span style="margin-left: 40px"
                    >Are you sure to delete ticket <strong># {{ deleteTicketId }}</strong></span
                >
            </div>
            <b-button class="mt-4 dc-button-primary" block @click="deleteTicket">Delete</b-button>
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";
import APICalls from "./APICalls";
import util from "./../../util/util";
import QuickbooksAPICalls from "./../quickbooks/APICalls";
// import CustomerDropdown from "./../../components/CustomerDropdown";

export default {
    name: "Tickets",
    // components: { CustomerDropdown },
    data() {
        return {
            columns: ["id", "customer.company", "products", "linteItemsQuantity", "linteItemsSku", "truck", "dateTime", "actions", "quickbooksInvoice"],
            deleteTicketId: "",
            ticketsOnHold: [],
            ticketsCompleted: [],
            ticketsAll: [],
            newInvoice: {
                message: "",
            },
            invoiceTicketsList: [],
            options: {
                perPage: 100,
                columnsDropdown: true,
                orderBy: {
                    column: "id",
                    ascending: false,
                },
                headings: {
                    id: "#",
                    "customer.company": "Customer",
                    linteItemsSku: "SKU",
                    linteItemsQuantity: "Quantity",
                    dateTime: "Date",
                    quickbooksInvoice: "QB #",
                },
                templates: {
                    linteItemsSku(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, lineitem => {
                            res += lineitem.item.sku + "\n";
                        });
                        return res;
                    },
                    linteItemsQuantity(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, lineitem => {
                            res += lineitem.quantity + "\n";
                        });
                        return res;
                    },
                    carryOver(h, ticket) {
                        return ticket.customer.carryOver;
                    },
                    dateTime(h, ticket) {
                        return util.formatTimestamp(ticket.createdAt);
                    },
                },
            },
        };
    },
    methods: {
        onChangeCheckbox(isChecked, row) {
            if (isChecked) {
                this.addToList(row);
            } else {
                this.removeFromList(row);
            }
        },
        onClickClearDropdown() {
            this.getTickets();
        },
        onClickCreateInvoice() {
            this.createInvoice();
        },
        onClickDeleteTicket(id) {
            this.deleteTicketId = id;
            this.$refs["dc-modal-delete-ticket"].show();
        },
        onClickEditTicket(id) {
            this.$router.push({ path: "/edit-ticket/" + id });
        },
        onClickGoToNewTicket() {
            this.$router.push({ path: "/new-ticket" });
        },
        onSelectCustomer(customer) {
            if (customer) {
                this.getTicketsForCustomer(customer.id);
            } else {
                this.getTickets();
            }
        },
        addToList(ticket) {
            this.invoiceTicketsList.push(ticket);
        },
        removeFromList(ticket) {
            let finalList = _.remove(this.invoiceTicketsList, tick => {
                return tick.id != ticket.id;
            });
            this.invoiceTicketsList = finalList;
        },
        async createInvoice() {
            let customerQBId = "";
            let customerEmail = "";
            let Line = [];

            let valid = true;

            // Validate Customer and Product
            _.each(this.invoiceTicketsList, ticket => {
                let ticketNumber = ticket.id;
                let timestamp = util.formatTimestamp(ticket.createdAt);
                let truck = ticket.truck;
                let PO = ticket.PO;

                // Each item in single ticket
                _.each(ticket.lineitems, eachItem => {
                    // let sku = eachItem.item.sku;
                    let productQBId = eachItem.item.quickbooksId;

                    // Validate same customer
                    if (customerQBId != "" && customerQBId != ticket.customer.quickbooksId) {
                        this.newInvoice.message = "Cannot create invoice for 2 different customers.";
                        this.$bvModal.show("dc-modal-create-invoice");
                        valid = false;
                        return false;
                    } else {
                        customerQBId = ticket.customer.quickbooksId;
                        if (ticket.customer.email) {
                            customerEmail = ticket.customer.email.split(",")[0].trim();
                        }
                    }

                    // Customer error
                    if (!customerQBId || customerQBId.trim().length === 0) {
                        this.newInvoice.message = "Customer not found in Quickbooks";
                        this.$bvModal.show("dc-modal-create-invoice");
                        valid = false;
                        return false;
                    }

                    // Product error
                    if (!productQBId || productQBId.trim().length === 0) {
                        this.newInvoice.message = "Product not found in Quickbooks";
                        this.$bvModal.show("dc-modal-create-invoice");
                        valid = false;
                        return false;
                    }

                    // Price
                    let quantity = eachItem.quantity;
                    let pricePerUnit = parseFloat(eachItem.item.price);
                    let amount = pricePerUnit * quantity;

                    // Description - Truck & PO
                    let Description = `#${ticketNumber} - ${timestamp} - QTY ${quantity}`;
                    if (truck && truck.trim().length !== 0) {
                        Description += ` - Truck #${truck}`;
                    }

                    if (PO && PO.trim().length !== 0) {
                        Description += ` - PO #${PO}`;
                    }

                    // Success - Push
                    Line.push({
                        DetailType: "SalesItemLineDetail",
                        SalesItemLineDetail: {
                            ItemRef: {
                                value: productQBId,
                            },
                            Qty: quantity,
                            UnitPrice: pricePerUnit,
                            TaxCodeRef: {
                                value: "39", // HST ON
                            },
                        },
                        Amount: amount,
                        Description,
                    });
                });
            });

            if (!valid) return;

            let params = {
                CustomerRef: {
                    value: customerQBId,
                },
                BillEmail: {
                    Address: customerEmail,
                },
                Line,
            };

            try {
                let response = await QuickbooksAPICalls.createInvoice(params);
                console.log(response);
                this.newInvoice.message = `Invoice created ! Invoice number ${response.Invoice.DocNumber}`;
                this.$bvModal.show("dc-modal-create-invoice");

                // Update Ticket's QB invoice number
                for (let i in this.invoiceTicketsList) {
                    let ticket = this.invoiceTicketsList[i];
                    await this.updateTicketQBInvoice(ticket.id, { quickbooksInvoice: response.Invoice.DocNumber });
                }

                // Clear Data
                this.invoiceTicketsList = [];
            } catch (e) {
                console.log(e);
                this.$toasted.show("Error: REFRESH TOKEN in Settings", { position: "bottom-center", className: "dc-toast-error", duration: 8000 });
            }
        },
        async deleteTicket() {
            try {
                await APICalls.deleteTicket(this.deleteTicketId);
                this.$toasted.show(`Ticket # ${this.deleteTicketId} deleted`, {
                    position: "bottom-center",
                    className: "dc-toast-success",
                    duration: 5000,
                });
                this.deleteTicketId = "";
                this.$refs["dc-modal-delete-ticket"].hide();
                this.getTickets();
            } catch (errResponse) {
                console.log(errResponse);
            }
        },
        async updateTicketQBInvoice(id, params) {
            await APICalls.editTicket(id, params);
            this.getTickets();
        },
        async getTickets() {
            this.ticketsAll = [];
            try {
                let tickets = await APICalls.getTickets();
                this.ticketsAll = tickets;
            } catch (e) {
                console.log(e);
                this.$router.push({ path: "/login" });
            }
        },
        async getTicketsForCustomer(id) {
            this.ticketsAll = [];
            try {
                console.log("GET", id);
                let tickets = await APICalls.getTicketsForCustomer(id);
                this.ticketsAll = tickets;
            } catch (errResponse) {
                console.log(errResponse);
            }
        },
    },
    watch: {
        ticketsAll: function(val) {
            this.ticketsOnHold = [];
            this.ticketsCompleted = [];

            _.each(val, ticket => {
                let products = "";
                _.each(ticket.lineitems, obj => {
                    products += obj.item.name + "\n";
                });

                ticket.products = products;

                // Completed
                if (ticket.quickbooksInvoice && ticket.quickbooksInvoice.length != 0) {
                    this.ticketsCompleted.push(ticket);
                }
                // On-Hold
                else {
                    this.ticketsOnHold.push(ticket);
                }
            });
        },
    },
    mounted() {
        this.getTickets();
        this.newInvoice.message = "";
    },
};
</script>

<style lang="scss">
#dc-tickets {
    #dc-header {
        background: transparent;
        box-shadow: none;
        display: flex;

        #dc-reload {
            cursor: pointer;
        }
    }

    .dc-actions-container {
        display: flex;
        align-items: center;

        .dc-icon-edit {
            width: 22px;
            height: 22px;
            margin-right: 20px;
            color: black;
            cursor: pointer;
        }

        .dc-icon-delete {
            width: 22px;
            height: 22px;
            margin-right: 20px;
            color: black;
            cursor: pointer;
        }

        .dc-button-primary {
            margin-top: 10px;
            width: 160px;
        }
    }

    #dc-dropdown-customer-container {
        width: 80%;
        display: flex;

        #dc-customer-dropdown {
            width: 80%;
        }
    }

    .VueTables {
        .row {
            // Columns - Dropdown
            .dropdown {
                .dropdown-toggle {
                    background-color: #007bc4 !important;
                    height: 45px;
                }

                .dropdown-menu {
                    padding: 10px;
                    font-size: 18px;

                    input {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    table {
        tbody {
            tr {
                td {
                    white-space: pre;
                    word-wrap: break-word;
                }
                td:nth-child(1) {
                    text-align: center;
                    font-weight: bold;
                }
                td:nth-child(2) {
                    font-size: 14px;
                }
                td:nth-child(4) {
                    font-size: 14px;
                    text-align: center;
                }
                td:nth-child(5) {
                    max-width: 120px;
                    word-wrap: break-word;
                    overflow-wrap: break-word !important;
                    white-space: normal;
                }
                td:nth-child(6) {
                    font-size: 14px;
                    text-align: center;
                    max-width: 120px;
                    word-wrap: break-word;
                    overflow-wrap: break-word !important;
                    white-space: normal;
                }
                td:nth-child(8) {
                    div {
                        display: flex;
                        justify-content: center;
                    }
                }
                td:nth-child(9) {
                    text-align: center;
                }
            }
        }
    }
}
</style>
